/* SURVEYS */
export const GET_SURVEYS = "GET_SURVEYS"
export const GET_SURVEYS_SUCCESS = "GET_SURVEYS_SUCCESS"
export const GET_SURVEYS_FAIL = "GET_SURVEYS_FAIL"

export const GET_SURVEY_DETAIL = "GET_SURVEY_DETAIL"
export const GET_SURVEY_DETAIL_SUCCESS = "GET_SURVEY_DETAIL_SUCCESS"
export const GET_SURVEY_DETAIL_FAIL = "GET_SURVEY_DETAIL_FAIL"

export const GET_SURVEY_QUESTIONS = "GET_SURVEY_QUESTIONS"
export const GET_SURVEY_QUESTIONS_SUCCESS = "GET_SURVEY_QUESTIONS_SUCCESS"
export const GET_SURVEY_QUESTIONS_FAIL = "GET_SURVEY_QUESTIONS_FAIL"

export const SET_ACTIVE_SURVEY = "SET_ACTIVE_SURVEY"
export const SET_ACTIVE_SURVEY_QUESTION = "SET_ACTIVE_SURVEY_QUESTION"

/* PHONES */
export const GET_PHONES = "GET_PHONES"
export const GET_PHONES_SUCCESS = "GET_PHONES_SUCCESS"
export const GET_PHONES_FAIL = "GET_PHONES_FAIL"

/**
 * add SURVEY
 */
 export const ADD_NEW_SURVEY = "ADD_NEW_SURVEY"
 export const ADD_SURVEY_SUCCESS = "ADD_SURVEY_SUCCESS"
 export const ADD_SURVEY_FAIL = "ADD_SURVEY_FAIL"

 /**
 * Edit SURVEY
 */
export const UPDATE_SURVEY = "UPDATE_SURVEY"
export const UPDATE_SURVEY_SUCCESS = "UPDATE_SURVEY_SUCCESS"
export const UPDATE_SURVEY_FAIL = "UPDATE_SURVEY_FAIL"

/**
 * Delete SURVEY
 */
export const DELETE_SURVEY = "DELETE_SURVEY"
export const DELETE_SURVEY_SUCCESS = "DELETE_SURVEY_SUCCESS"
export const DELETE_SURVEY_FAIL = "DELETE_SURVEY_FAIL"

